<template>
  <div class="exh_content">
    <div v-for="(item,index) in myList" :key="index" class="exh">
      <p class="exh_title">{{ item.myTitle }}</p>
      <p class="title_text">您认为{{ item.myTitle }}的同事和分数</p>
      <ul>
        <li v-for="(v,i) in item.childrenList" :key="i">{{ v.name }} {{ v.score }}分</li>
      </ul>
    </div>
    <div class="exh_btn">
      <el-button type="info" @click="froward">上一步</el-button>
      <el-button type="danger" @click="submit">提交</el-button>
    </div>
  </div>
</template>

<script>
import { toSubmit, getCultureResultApi, getCulture } from '@/api/assessment'
export default {
  data() {
    return {
      data: [],
      exam_date: '',
      name: 'answer',
      page: 0,
      myList: []
    }
  },
  created() {
    this.isSubmint()
    this.page = this.$route.query.page
    this.exam_date = sessionStorage.getItem('DATE')
    this.getData()
  },
  mounted() {
    document.title = sessionStorage.getItem('name_zl') + '-企业文化考核'
  },
  methods: {
    // 是否已答过企业文化
    isSubmint() {
      const date = new Date()
      const y = date.getMonth() == 0 ? date.getFullYear() - 1 : date.getFullYear()
      const m = date.getMonth() == 0 ? 12 : date.getMonth() < 10 ? '0' + date.getMonth() : date.getMonth()
      const params = {
        exam_date: y + '-' + m
      }
      getCulture(params).then(res => {
        if (res.errNo === 1004) {
          console.log('本人已答过当月文化考核')
          this.$router.push({
            path: '/assessment/assuccess'
          })
        }
      })
    },
    froward() {
      sessionStorage.setItem('isAgain', 0)
      this.$router.go(-1)
      // this.$router.push({ path: '/assessment/cultrue', query: { exam_date: this.exam_date, isAgain: 0 }})
    },
    submit() {
      if (confirm('请确认之后再提交，提交之后不可更改')) {
        const params = {
          exam_date: this.exam_date,
          page: this.page,
          name: 'answer'
        }
        toSubmit(params).then(res => {
          if (res.errNo === 0) {
            this.$router.push({ path: '/assessment/assuccess' })
          } else {
            this.$message.error(res.result)
          }
        })
      }
    },
    getData() {
      const params = {
        exam_date: this.exam_date,
        page: this.page,
        name: 'answer'
      }
      getCultureResultApi(params).then(res => {
        const keys = Object.keys(res.result.result)
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i]
          const value = res.result.result[key]
          this.myList.push({
            myTitle: key,
            childrenList: value
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

/deep/ .el-button {
  color: #000;
  background: #dddddd;
  border: 0;
}
/deep/ .el-button--danger {
  color: #fff;
  background-color: #f00;
}
.exh_content {
  margin-top: 100px;
  border: 1px solid;
  width: 80%;
  margin: 0 auto;
  padding: 20px;
}
.exh {
    width: 1000px;
    margin: 0 auto;
    .exh_title {
        background-color: #f00;
        width: 200px;
        height: 50px;
        color: #fff;
        text-align: center;
        line-height: 50px;
        font-size: 20px;
        margin-left: 200px;
    }
    .title_text {
        text-align: left;
        font-size: 18px;
        margin-top: 20px;
    }
    li{
        text-align: left;
        margin-top: 10px;
        font-size: 16px;
    }
}
</style>
